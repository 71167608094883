<template>
  <v-app>
    <app-title-header></app-title-header>
    <v-main>
      <v-container>
        <div v-if="message === ''">Eメールアドレス確認中...</div>
        <div v-else>
          <v-alert :type="alertType" outlined>{{ message }}</v-alert>
        </div>
      </v-container>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import { getDeviceAlertSubscriptionConfirm } from '@/api'
import AppTitleHeader from '@/components/app-title-header.vue'
import AppFooter from '@/components/app-footer.vue'

const errorAlreadyEmailConfirmed = 'already email confirmed'

export default {
  name: 'DeviceAlertSubscriptionConfirm',
  components: {
    AppTitleHeader,
    AppFooter
  },
  data() {
    return {
      message: '',
      alertType: ''
    }
  },
  async mounted() {
    if (
      this.$route.query.group_id === undefined ||
      this.$route.query.hash === undefined
    ) {
      this.message = '無効なパラメータです'
      this.alertType = 'error'
      return
    }
    try {
      await getDeviceAlertSubscriptionConfirm(
        this.$route.query.group_id,
        this.$route.query.hash
      )
    } catch (e) {
      if (e.response.data.message === errorAlreadyEmailConfirmed) {
        this.message = 'Eメールアドレスはすでに確認済みです'
        this.alertType = 'success'
        return
      }
      this.message = '無効なパラメータです'
      this.alertType = 'error'
      return
    }
    this.message = 'Eメールアドレスを確認しました'
    this.alertType = 'success'
  }
}
</script>
